import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Snackbar,
  FormGroup,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import carrierService from "../../services/carrier.service";
import { useAuth } from "../../hooks/useAuth";
import gaContracts from "../../services/ga-contract.service";
import CarrierSelection from "./CarrierSelection";
import agentService from "../../services/agent.service";
import currency from "currency.js";
import { useNavigate } from "react-router-dom";
import FormInput, { DateInput, SSNInput } from "../FormInput";
import SelectInput from "../SelectInput";

import { statesList } from "../../lib/constants";
import dayjs from "dayjs";

const validationSchema = Yup.object().shape({
  ga: Yup.string(),
  payee: Yup.string(),
  carriers: Yup.array().required().min(1),
  agentSnapshot: Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string().required("Last Name is required"),
    preferredName: Yup.string(),
    email: Yup.string()
      .email("Must be valid e-mail")
      .required("E-Mail is required"),
    phone: Yup.string(),
    addressStreet: Yup.string().required("Address is required"),
    addressState: Yup.string().required("State is required"),
    addressCity: Yup.string().required("City is required"),
    addressZip: Yup.string().required("Zip is required"),
    ssn: Yup.string()
      .optional()
      .matches(/^\d{3}-?\d{2}-?\d{4}$/, {
        message: "Must be a SSN of the form XXX-YY-ZZZZ",
      }),
    dob: Yup.date()
      .transform((val, original, context) => {
        // This means it's fine.
        if (context.isType(val)) return val;

        // If it's not touched yet, original is null.
        if (original === null) return undefined;

        // If nothing is entered, we don't have a real date (and it's optional, so it's not an error)
        if (original === "" || original.match(/^[_/]+$/)) {
          return undefined;
        }

        // The original coercion failed so try with dayjs
        // Parse with dayjs
        const parsed = dayjs(original, "MM/DD/YYYY");

        // If not valid, return an invalid date
        if (!parsed.isValid()) return new Date("");

        return parsed.toDate();
      })
      .optional(),
    upline: Yup.string(),
    topOfHierarchy: Yup.string(),
  })
});

const InputStyle = {
  marginBottom: 2,
};

const GAContract = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [carrierList, setCarrierList] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [currentGA, setCurrentGA] = useState(false);
  const [canEditAgentFields, setCanEditAgentFields] = useState(false);
  const navigate = useNavigate();
  const { user, loading } = useAuth();

  const initialValues = {
    payee: "",
    carriers: [],
    notifyAfterApproval: false,
    notes: "",
    agentSnapshot: {
      npn: "",
      firstName: "",
      lastName: "",
      preferredName: "",
      email: "",
      phone: "",
      addressStreet: "",
      addressState: "",
      addressCity: "",
      addressZip: "",
      upline: "",
      topOfHierarchy: "",
      ssn: "",
      dob: ""
    }
  };

  useEffect(() => {
    if (!user && !loading) {
      navigate("/login");
      return;
    }

    carrierService.list(user).then((data) => {
      setCarrierList(data);
    });
  }, [user, loading]);

  async function lookupGA(npn, setFieldValue, resetForm) {
    try {
      // Reset the form, but keep the NPN -- we're going to get
      // a new set of values coming in.
      await resetForm();
      await setFieldValue("agentSnapshot.npn", npn);
      const response = await agentService.findByNPN(npn);

      // Pre-populate our agentSnapshot with the response data, if
      // we have it.
      if(response !== null) {
        await setFieldValue("agentSnapshot", response);
        setCurrentGA(response);
        setCanEditAgentFields(false);
      } else {
        setCurrentGA(null);
        setCanEditAgentFields(true);
      }

      return response;
    } catch (err) {
      setCanEditAgentFields(true);
      setErrorMessage(err.message ?? JSON.stringify(err));
      console.error(err);
    }
  }

  async function submit(data) {
    try {
      await gaContracts.request(data);
      setSuccessMessage("GA Contract request submitted!");
      setCurrentGA(false);
      if (user) {
        setTimeout(() => {
          window.location.href = "/admin/ga-contract";
        }, 2000);
      }
      return true;
    } catch (err) {
      setErrorMessage(err.message ?? JSON.stringify(err));
      console.error(err);
      return false;
    }
  }

  const formatValue = (unit, value) => {
    if(unit === "dollar") {
      return currency(value).format();
    } else {
      return `${value}%`;
    }
  }

  async function onSubmit(values, { resetForm }) {
    var success = await submit(values, resetForm);
    if(success) resetForm();
  }

  return (
    <>
      {errorMessage && (
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setErrorMessage(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      {successMessage && (
        <Snackbar
          open={!!successMessage}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage(null)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSuccessMessage(null)}
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      )}
      <Typography
        variant="h4"
        sx={{ marginBottom: 2, marginTop: -4 }}
        textAlign={"center"}
      >
        GA Request
      </Typography>
      <Formik initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}>
        {({ values, setFieldValue, resetForm, isValid }) => (
        <Form>
          <Field name="agentSnapshot.npn"
                 label="Agent NPN"
                 fullWidth
                 variant="outlined"
                 onBlur={()=>lookupGA(values.agentSnapshot.npn, setFieldValue, resetForm)}
                 component={FormInput}
          />
          {currentGA === false && (
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Alert severity="info">
                  Enter NPN to lookup GA. If agent is not present in the database,
                  you will be prompted to provide their information.
                </Alert>
              </CardContent>
            </Card>
          )}
          {currentGA === null && (
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Alert severity="error">
                  No GA found. If you are sure that the NPN was entered
                  correctly, please fill in their information below as part of your request.
                </Alert>
              </CardContent>
            </Card>
          )}
          {(currentGA === null || currentGA) && (
            <Card sx={{ minWidth: 275, marginTop: "1rem" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      disabled={!canEditAgentFields}
                      name="agentSnapshot.firstName"
                      autoComplete="given-name"
                      label="First Name"
                      fullWidth
                      component={FormInput}
                      variant="outlined"
                    />
                  </Grid>
                <Grid item xs={6}>
                  <Field
                    disabled={!canEditAgentFields}
                    name="agentSnapshot.lastName"
                    autoComplete="family-name"
                    label="Last Name / Company Name (for agency)"
                    fullWidth
                    component={FormInput}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Field
                disabled={!canEditAgentFields}
                name="agentSnapshot.preferredName"
                autoComplete="nickname"
                label="Preferred Name"
                fullWidth
                component={FormInput}
                variant="outlined"
              />
              <Field
                disabled={!canEditAgentFields}
                name="agentSnapshot.email"
                autoComplete="email"
                label="E-Mail"
                fullWidth
                component={FormInput}
                variant="outlined"
              />

              {canEditAgentFields && (
              <>
              <Field
                name="agentSnapshot.phone"
                autoComplete="tel"
                label="Phone"
                fullWidth
                component={FormInput}
                variant="outlined"
              />
              <Field
                name="agentSnapshot.addressStreet"
                autoComplete="street-address"
                label="Address"
                fullWidth
                component={FormInput}
                variant="outlined"
              />
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Field
                    name="agentSnapshot.addressCity"
                    autoComplete="address-level2"
                    label="City"
                    fullWidth
                    component={FormInput}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="agentSnapshot.addressState"
                    autoComplete="address-level1"
                    label="State"
                    component={SelectInput}
                    variant="outlined"
                    options={statesList}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="agentSnapshot.addressZip"
                    autoComplete="postal-code"
                    label="Zip"
                    fullWidth
                    component={FormInput}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name="agentSnapshot.ssn"
                    autoComplete="ssn"
                    label="Social Security Number"
                    fullWidth
                    component={SSNInput}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="agentSnapshot.dob"
                    autoComplete="bday"
                    label="Date of Birth"
                    variant="outlined"
                    fullWidth
                    component={DateInput}
                  />
                </Grid>
              </Grid>
              <Field
                name="agentSnapshot.upline"
                autoComplete="organization"
                label="Immediate Upline - Please enter the name of the agency you are working for"
                fullWidth
                component={FormInput}
                variant="outlined"
              />
              <Field
                name="agentSnapshot.topOfHierarchy"
                label="Top of Hierarchy"
                fullWidth
                component={FormInput}
                variant="outlined"
              />
              </>
              )}
              </CardContent>
            </Card>
          )}
          <Divider sx={{ my: 2 }} />
          <CarrierSelection carrierList={carrierList} />
          <Box sx={{ marginBottom: 1 }}>
            <label>
              <Field type="checkbox" name="notifyAfterApproval" />
              Send confirmation email to agent
            </label>
          </Box>

          {/* Notes */}
          <Box sx={{ marginBottom: 1 }}>
            <Field name="notes">
              {({field, form, meta}) => (
              <TextField
                sx={InputStyle}
                error={
                  form.touched[field.name] && form.errors[field.name]
                    ? true
                    : false
                }
                {...field}
                label="Notes"
                multiline
                rows={5}
                fullWidth
              />
              )}
            </Field>
          </Box>

          {/* Confirmation modal */}
          <Dialog
            open={confirmationOpen}
            fullWidth={true}
            maxWidth={"lg"}
            onClose={() => setConfirmationOpen(false)}
          >
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Are you sure you want to submit this GA Contract request?
                </Typography>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      GA Info
                    </Typography>
                    <Typography variant="h5" component="div">
                      {values.agentSnapshot.firstName} {values.agentSnapshot.lastName} - NPN{" "}
                      {values.agentSnapshot.npn}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      {values.agentSnapshot.email} - Confirmation will{" "}
                      {values.notifyAfterApproval ? "" : "not"} be sent
                    </Typography>
                    <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label="Carrier selection"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: 210 }}>Carrier</TableCell>
                            <TableCell align="right">
                              New Policy Amount
                            </TableCell>
                            <TableCell align="right">
                              Contract Renewal Amount
                            </TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>Will have LOA Agents?</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.carriers.map((row, index) => {
                            const carrier = carrierList.find((c) => c.id === row.id);
                            const startDate =
                              typeof row.startDate === "string"
                                ? row.startDate
                                : row.startDate.format("MM/DD/YYYY");

                            return (
                              <TableRow
                                key={carrier.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {carrier.value}
                                </TableCell>
                                <TableCell align="right">
                                  {formatValue(row?.newPolicyUnit, row?.newPolicyAmount)}
                                </TableCell>
                                <TableCell align="right">
                                  {formatValue(row?.renewalUnit, row?.renewalAmount)}
                                </TableCell>
                                <TableCell>{startDate}</TableCell>
                                <TableCell align="right">
                                  {row?.hasLOAAgents ? "Yes" : "No"}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmationOpen(false)}>Cancel</Button>
              <Button
                type="submit"
                onClick={() => submit(values).then(setConfirmationOpen(false))}
                disabled={!isValid}
                variant="contained"
              >
                Confirm GA Contract Request
              </Button>
            </DialogActions>
          </Dialog>
          <Button
            type="button"
            disabled={!isValid}
            fullWidth
            onClick={() => setConfirmationOpen(true)}
            variant="contained"
          >
            Submit GA Contract Request
          </Button>
        </Form>
      )}
      </Formik>
    </>
  );
};

export default GAContract;
