import { Alert, Button, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import FormInput, { DateInput, SSNInput } from "../FormInput";
import SelectInput from "../SelectInput";
import dayjs from "dayjs";
import { useAgencyLinkData } from "../../hooks/useAgencyLink";

import { statesList } from "../../lib/constants";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().optional(),
  lastName: Yup.string().required("Last Name is required"),
  preferredName: Yup.string(),
  email: Yup.string()
    .email("Must be valid e-mail")
    .required("E-Mail is required"),
  phone: Yup.string(),
  address: Yup.string().required("Address is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  zip: Yup.string().required("Zip is required"),
  ssn: Yup.string()
    .optional()
    .matches(/^\d{3}-?\d{2}-?\d{4}$/, {
      message: "Must be a SSN of the form XXX-YY-ZZZZ",
    }),
  dob: Yup.date()
    .transform((val, original, context) => {
      // This means it's fine.
      if (context.isType(val)) return val;

      // If it's not touched yet, original is null.
      if (original === null) return undefined;

      // If nothing is entered, we don't have a real date (and it's optional, so it's not an error)
      if (original === "" || original.match(/^[_/]+$/)) {
        return undefined;
      }

      // The original coercion failed so try with dayjs
      // Parse with dayjs
      const parsed = dayjs(original, "MM/DD/YYYY");

      // If not valid, return an invalid date
      if (!parsed.isValid()) return new Date("");

      return parsed.toDate();
    })
    .optional(),
  upline: Yup.string(),
  topOfHierarchy: Yup.string(),
  commissionEmail: Yup.string().email("Must be a valid email").optional(),
  enrollmentEmail: Yup.string().email("Must be a valid email").optional(),
});

const AgentInfo = ({ user, data, completeStep }) => {
  const { linkData } = useAgencyLinkData();
  const [newData, setNewData] = useState({...data});

  useEffect(() => {
    setNewData({...data});
  }, [data]);

  const submit = async (data) => {
    completeStep(data);
  };

  return (
    <Formik
      initialValues={{
        firstName: newData?.firstName ? newData?.firstName : "",
        lastName: newData?.lastName ? newData?.lastName : "",
        preferredName: newData?.preferredName ? newData?.preferredName : "",
        email: newData?.email ? newData?.email : "",
        phone: newData?.phone ? newData?.phone : "",
        address: newData?.address ? newData?.address : "",
        state: newData?.state ? newData?.state : "",
        city: newData?.city ? newData?.city : "",
        zip: newData?.zip ? newData?.zip : "",
        upline: newData?.upline || linkData?.upline || "",
        topOfHierarchy: newData?.topOfHierarchy || linkData?.topOfHierarchy || "",
        ssn: newData?.ssn ? newData?.ssn : "",
        dob: newData?.dob ?? null, // NOT UNDEFINED!!!!!!
        commissionEmail: newData?.commissionEmail ?? "",
        enrollmentEmail: newData?.enrollmentEmail ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        submit(values);
      }}
    >
      {({ values, onBlur, onChange }) => (
        <Form>
          <Grid container spacing={2}>
            {newData?.agent ? (
              ""
            ) : (
              <Alert severity="warning">
                No such agent or agency was found with that NPN. Please enter
                information below.
              </Alert>
            )}
            <Grid item xs={12}></Grid>
            <Grid item xs={6}>
              <Field
                name="firstName"
                autoComplete="given-name"
                label="First Name"
                fullWidth
                component={FormInput}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="lastName"
                autoComplete="family-name"
                label="Last Name / Company Name (for agency)"
                fullWidth
                component={FormInput}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Field
            name="preferredName"
            autoComplete="nickname"
            label="Preferred Name"
            fullWidth
            component={FormInput}
            variant="outlined"
          />
          <Field
            name="email"
            autoComplete="email"
            label="E-Mail"
            fullWidth
            component={FormInput}
            variant="outlined"
          />
          <Field
            name="phone"
            autoComplete="tel"
            label="Phone"
            fullWidth
            component={FormInput}
            variant="outlined"
          />
          <Field
            name="address"
            autoComplete="street-address"
            label="Address"
            fullWidth
            component={FormInput}
            variant="outlined"
          />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Field
                name="city"
                autoComplete="address-level2"
                label="City"
                fullWidth
                component={FormInput}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="state"
                autoComplete="address-level1"
                label="State"
                component={SelectInput}
                variant="outlined"
                options={statesList}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="zip"
                autoComplete="postal-code"
                label="Zip"
                fullWidth
                component={FormInput}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                name="ssn"
                autoComplete="ssn"
                label="Social Security Number"
                fullWidth
                component={SSNInput}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="dob"
                autoComplete="bday"
                label="Date of Birth"
                variant="outlined"
                fullWidth
                component={DateInput}
              />
            </Grid>
          </Grid>
          <Field
            name="upline"
            autoComplete="organization"
            label="Immediate Upline - Please enter the name of the agency you are working for"
            fullWidth
            component={FormInput}
            variant="outlined"
          />
          <Field
            name="topOfHierarchy"
            label="Top of Hierarchy"
            fullWidth
            component={FormInput}
            variant="outlined"
          />
          {/* If the agent is not entered into the CRM, we need to collect optional commission and enrollment emails */}
          {!newData?.agent && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name="commissionEmail"
                    autoComplete="email"
                    label="Commission E-Mail"
                    fullWidth
                    component={FormInput}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="enrollmentEmail"
                    autoComplete="email"
                    label="Enrollment E-Mail"
                    fullWidth
                    component={FormInput}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Button type="submit" fullWidth variant="contained">
            Next
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AgentInfo;
