import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";

function transformCarrierData(gaRequest, carrierData, carriers, agencies, users) {
  const {
    id,
    payee,
    notifyAfterApproval,
    agentSnapshot,
    agency: agencyId,
    user: userId,
    notes,
    status,
    approval,
    emailRecipients,
    createdAt,
    updatedAt,
  } = gaRequest;

  const {
    npn,
    firstName,
    lastName,
    phone,
    email,
    addressStreet,
    addressCity,
    addressState,
    addressZip,
    upline,
    ssn,
    dob,
    topOfHierarchy,
  } = agentSnapshot;

  const {
    carrier: carrierId,
    newPolicyAmount,
    renewalAmount,
    startDate,
    hasLOAAgents,
    level,
    processor: processorId,
  } = carrierData;

  const carrier = carriers[carrierId];
  const carrierName = carrier?.value || carrierId;
  const agency = agencies[agencyId];
  const user = users[userId];


  const processor = users[processorId];
  const processorName = (processor) ? `${processor?.firstName} ${processor?.lastName} (${processor?.email})` : "";

  const agencyName = agency?.name || "N/A";
  const emailRecipientList = emailRecipients.join("; ");
  const userDescription = user ? `${user.firstName} ${user.lastName} (${user.email})` : "Unknown";

  const loaAgentsText = (hasLOAAgents) ? "Yes" : "No";

  return {
    "GA Request ID": id,
    "Carrier Name": carrierName,
    "New Policy Amount": newPolicyAmount,
    "Renewal Amount": renewalAmount,
    "Has LOA Agents?": loaAgentsText,
    "Level": level,
    "Processor": processorName,
    "Start Date": startDate,
    "Agent NPN": npn,
    "Agent First Name": firstName,
    "Agent Last Name": lastName,
    "Agent Email": email,
    "Agent Phone": phone,
    "Agent Address": addressStreet,
    "Agent City": addressCity,
    "Agent State": addressState,
    "Agent ZIP": addressZip,
    "Agent SSN": ssn,
    "Agent DOB": dob,
    "Agent Upline": upline,
    "Agent Top of Hierarchy": topOfHierarchy,
    "Notify Agent After Approval": notifyAfterApproval ? "Yes" : "No",
    "Notes": notes,
    "Agent or Agency": payee,
    Status: status,
    Approval: approval,
    Agency: agencyName,
    "Submitted By": userDescription,
    "Email Recipients": emailRecipientList,
    Created: createdAt,
    Updated: updatedAt,
  };
}

// transformContractRequest takes a contract request and breaks it out into individual contracts
function transformGARequest(gaRequest, carriers, agencies, users) {
  return gaRequest.carriers.map((carrier) => transformCarrierData(gaRequest, carrier, carriers, agencies, users));
}

const headers = [
  "GA Request ID",
  "Carrier Name",
  "New Policy Amount",
  "Renewal Amount",
  "Has LOA Agents?",
  "Level",
  "Processor",
  "Start Date",
  "Agent NPN",
  "Agent First Name",
  "Agent Last Name",
  "Agent Email",
  "Agent Phone",
  "Agent Address",
  "Agent City",
  "Agent State",
  "Agent ZIP",
  "Agent SSN",
  "Agent DOB",
  "Agent Upline",
  "Notify Agent After Approval",
  "Notes",
  "Agent or Agency",
  "Status",
  "Approval",
  "Agency",
  "Submitted By",
  "Email Recipients",
  "Created",
  "Updated",
];

const exporter = async (records, fetchRelatedRecords) => {
  // Break out records into individual contracts
  const agencies = await fetchRelatedRecords(records, "agency", "agency");
  const users = await fetchRelatedRecords(records, "user", "user");
  const carrierData = records.map((cr) => cr.carriers);
  const carriers = await fetchRelatedRecords(carrierData, "carrier", "carrier");
  const data = records.map((cr) =>
    transformGARequest(cr, carriers, agencies, users)
  );
  const report = await jsonExport(data, { headers });
  return downloadCSV(report, "GA Requests");
};

export default exporter;
